import type { ComponentPropsWithoutRef, PropsWithChildren } from "react";

import { cn } from "~/utils/classnames";

type PageContainerProps = PropsWithChildren<ComponentPropsWithoutRef<"div">>;

export function PageContainer({ children, className }: PageContainerProps) {
  return (
    <div
      className={cn(
        "pb-8 pt-4 sm:pb-10 sm:pt-5 md:pb-12 md:pt-6 lg:pb-14 lg:pt-7 xl:pb-16 xl:pt-8",
        className
      )}
    >
      {children}
    </div>
  );
}
